import { tw } from "twind";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faGithub, faTwitter, faSpotify, faDiscord, faGoogle } from '@fortawesome/free-brands-svg-icons';


export default function App() {

  const links = [
    {
      icon: faInstagram,
      href: "https://www.instagram.com/olyamrshn",
    },
    {
      icon: faGithub,

      href: "https://github.com/olyamrshn",
    },
    {
      icon: faTwitter,

      href: "https://twitter.com/leatbabies",
    },
    {
      icon: faSpotify,

      href: "https://open.spotify.com/user/awpkpo5yx2t80iqa4tgfvp7a7",
    },
    {
      icon: faDiscord,

      href: "https://discord.com/users/704485765010161966",
    },
    {
      icon: faGoogle,
      href: "mailto:olyamrshn@gmail.com",
    },
  ];

  const experience = [
    {
      title: "Learn Anything | Frontend Engineer (2023 - Present)",
      points: [
        "frontend development for an open-source educational platform featuring thousands of curated learning topics",
        "Built responsive interfaces for desktop and mobile web versions, plus contributed to mobile app development",
        "Implemented core features including user notes, task management, and advanced search functionality",
        "Created an intuitive user profile system for saving resources and managing learning paths"
      ]
    },
    {
      title: "Zuleika Jewelry | Frontend Engineer (2024 - Present)",
      points: [
        "Developed and launched a comprehensive e-commerce interface for a jewelry store on Shopify platform",
        "Implemented responsive product galleries, collection displays, and detailed product cards",
        "Built custom shopping features optimizing the customer journey and product discovery",
        "Integrated seamless checkout and inventory management systems"
      ]
    }
  ];

  const achievements = [
    {
      title: "Winner, TON Paris Hackathon (October 2024)",
      subtitle: "Frontend Developer - TON Todo Escrow Application",
      description: "frontend development of a Telegram mini-app connecting entrepreneurs with investors via a task-based escrow system. A secure platform allowing entrepreneurs to structure projects into funded tasks while protecting investor assets through blockchain-based escrow functionality.",
      points: [
        "Implemented intuitive UI for project creation and investment workflows",
        "Developed interactive components for task management and budget allocation",
        "Built dispute resolution interface with automated moderator chat system",
        "Created responsive layouts optimized for Telegram platform",
        "Collaborated on smart contract integration with backend team"
      ]
    }
  ];

  const education = [
    {
      title: "Saint-Petersburg University of Economics, German Studies (2019 - 2022)",
      subtitle: "Bachelor's Degree",
      points: [
        "Applied Excel and SQL for economic data analysis and reporting",
        "Conducted financial modeling and balance sheet calculations",
        "Worked with business analytics and database management"
      ]
    },
    {
      title: "Higher School of Economics (HSE), Asian Studies (2018 - 2019)",
      points: [
        "Used Python for text analysis and processing",
        "Developed data visualization tools for textual research"
      ]
    }
  ];

  const additionalEducation = [
    {
      title: "Yandex Practicum | Frontend Full Development Course - 2022 (1 year)",
      points: [
        "Developed modern responsive web applications using JavaScript, React, and Node.js",
        "Created cross-browser compatible layouts using HTML5 and CSS3",
        "Built RESTful APIs and worked with databases",
        "Implemented user authentication and authorization",
        "Wrote and executed unit tests using Jest",
        "Performed API testing with Postman",
      ]
    }
  ];

  const SkillsSection = () => {
    const skills = {
      "Core Technologies": [
        "TypeScript",
        "REST API",
        "Three.js"
      ],
      "Frameworks & Libraries": [
        "React.js",
        "Next.js",
        "React Native",
        "TailwindCSS",
        "Tanstack Query",
        "Framer Motion",
      ]
    };

    return (
      <section className={tw`mb-8`}>
      <h2 className={tw`text-base font-regular mb-4 text-left`}>TECHNICAL SKILLS</h2>
      {Object.entries(skills).map(([category, items]) => (
        <div key={category} className={tw`mb-6`}>
          <h3 className={tw`text-sm mb-3 text-left`}>{category}</h3>
          <div className={tw`flex flex-wrap gap-3`}>
            {items.map((skill) => (
              <span
                key={skill}
                className={tw`
                  text-sm px-4 py-2
                  bg-gradient-to-r from-purple-500/20 to-blue-500/20
                  border border-purple-500/30
                  rounded-lg
                  transition-all duration-300
                  hover:from-purple-500/30 hover:to-blue-500/30
                  hover:border-purple-500/50
                  hover:scale-105
                  cursor-default
                `}
              >
                {skill}
              </span>
            ))}
          </div>
        </div>
      ))}
    </section>
    );
  };

  const Section = ({ title, children }) => (
    <section className={tw`mb-8`}>
      <h2 className={tw`text-base font-regular mb-4 text-left`}>{title}</h2>
      {children}
    </section>
  );

  const ExperienceItem = ({ item }) => (
    <div className={tw`mb-6`}>
      <h3 className={tw`text-sm font-regular whitespace-normal text-left`}>{item.title}</h3>
      {item.subtitle && <p className={tw`text-sm opacity-70 whitespace-normal text-left`}>{item.subtitle}</p>}
      {item.description && <p className={tw`text-sm mt-2 whitespace-normal text-left`}>{item.description}</p>}
      <ul className={tw`text-sm list-disc pl-4 mt-2 opacity-70`}>
        {item.points.map((point, index) => (
          <li key={index} className={tw`whitespace-normal mb-2 text-left`}>{point}</li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className={tw`text-white mx-auto mb-[2em] max-w-3xl user-select-none`}>
      <div className={tw`text-white mx-auto max-w-screen-md px-4 text-left`}>
        <h1 className={tw`text-base font-regular mb-3`}>Marshennikova Olga</h1>
        <p className={tw`text-sm mb-3 opacity-70`}>Frontend Engineer</p>

        <Section title="PROFESSIONAL EXPERIENCE">
          {experience.map((item, index) => (
            <ExperienceItem key={index} item={item} />
          ))}
        </Section>

      <Section title="ACHIEVEMENTS">
        {achievements.map((item, index) => (
          <ExperienceItem key={index} item={item} />
        ))}
      </Section>

      <Section title="EDUCATION">
        {education.map((item, index) => (
          <ExperienceItem key={index} item={item} />
        ))}
      </Section>

      <Section title="ADDITIONAL EDUCATION">
        {additionalEducation.map((item, index) => (
          <ExperienceItem key={index} item={item} />
        ))}
      </Section>

      <SkillsSection />

      <div className={tw`flex flex-row justify-center gap-6 mt-8`}>
        {links.map((link, index) => (
          <a
            key={index}
            href={link.href}
            className={tw`text-xl hover:text-purple-500 transition-colors duration-300`}
          >
            <FontAwesomeIcon icon={link.icon} />
          </a>
        ))}
      </div>
      </div>
    </div>
  );
}
